import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";

const CardItem = ({item, setItem, index, selectTab, setModal, setSelectDate}) =>{
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>{item?.employee?.name}</td>
            <td>{item?.employee?.department?.name}</td>
            <td>{item.loan}</td>
            <td>{item.no_of_installments}</td>
            <td style={{display: 'grid', gap: '8px', gridTemplateColumns: 'auto auto auto'}}>
                {item?.loan_dates?.map(res=> {
                    return <p 
                        onClick={()=> {
                            if(isExist('edit_loans' && selectTab === 'has_loan')){
                                setSelectDate(res)
                                setItem(item)
                                setModal(true)
                            }
                        }}
                        key={res?.id}
                        style={{
                            padding: '8px',
                            border: '1px solid #dedede',
                            borderRadius: '5px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            gap: '8px'
                        }} 
                        className="d-flex mb-0"
                    >
                        <span>{Translate[lang][res?.month?.toLowerCase()]}</span>
                        <span>{res?.year}</span>
                    </p>
                })}
            </td>
            <td>{item.start_date?.split('T')[0]}</td>
            <td>{item.note || '-'}</td>
            {/* <td>
                {() && <Dropdown>
                    <Dropdown.Toggle
                        className="light sharp i-false"
                    >
                        <i className="la la-ellipsis-v" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> {
                            setItem(item)
                            setAddModal(true)
                        }}> 
                            <i className='la la-edit'></i> {Translate[lang]?.freez}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </td> */}
            </tr>
    )
}
export default CardItem;