export const English = {
  there_are_no_data: "There are no data",
  effective_date: "Effective Date",
  increment: "Increment",
  allowances: "Allowances",
  allowance_name: "Allowance Name",
  has_allowances: "Has Allowances",
  assign_allowances: "Assign Allowances",
  assign_date: "Assign Date",
  fees: "Fees",
  fee: "Fee",
  bank: "Bank",
  passport_no: "Passport No",
  employee_letters: "Employee Letters",
  employer_name: "Employer Name",
  hr_name: "Hr Name",
  passport_expiry_date: "Passport Expiry Date",
  benefits: "Benefits",
  date_of_join: "Date of Join",
  personal_informations : "Personal Informations ",
  bonus: "Bonus",
  annual: "Annual",
  attendance: "Attendance",
  admin_updated_successfully: "Admin Updated Successfully",
  provision: "Provision",
  track_id: "Track ID",
  tran_id: "Tran ID",
  date: "Date",
  type: "Type",
  paid: "Paid",
  not_paid: "Not Paid",
  indemnity: "Indemnity",
  contract_no: "Contract No",
  start_day: "Start Day",
  package_num: "Package Num",
  company_projects: "Company Projects",
  existing_projects: "Existing Projects",
  hosting_projects: "Hosting Projects",
  hosting: "Hosting",
  server: "Server",
  leave_balance: "Leave Balance", 
  on_vaction: "On Vacation",
  vacation_history: "Vacation History",
  history: "History",
  leads: 'Leads',
  all: "All",
  employees_on_vacation: "Employees on Vacation",
  cancellation_of_resignation: "Cancellation of resignation",
  notice_period: "Notice Period",
  resignation: "Resignation",
  start_resignation_date: "Start Resignation Date",
  end_resignation_date: "End Resignation Date",
  lead_name: "Lead Name",
  reference: "Reference",
  revenues: "Revenues",
  year: "Year",
  year_revenues: "Year Revenues",
  year_expenses: "Year Expenses",
  custody: "Custody",
  expenses: "Expenses",
  financial: "Financial",
  client_email: "Client Email",
  contract_date: "Contract Date",
  upload_contract: "Upload Contract",
  client_civil_id: "Client Civil Id",
  att_alert: 'Please Enter Two Photos',
  from_front: "From Front",
  from_back: "From Back",
  payment_link: "Payment Link",
  profits: "Profits",
  payment_start_date: "Payment Start Date",
  freez: "Freez",
  allowances_options: "Allowances Options",
  allowances_in_vacations: "Allowances in Vacations",
  allowances_in_sick_leave: "Allowances in Sick Leave",
  allowances_not_in_vacations: "Allowances Not in Vacations",
  allowances_not_in_sick_leave: "Allowances Not in Sick Leave",
  excluded_employees_from_setting: "Excluded Employees From Setting",
  projects: "Projects",
  loans: "Loans",
  loan: "Loan",
  installments: "Installments",
  remaining_installment: "Remaining Installment",
  no_of_installments: "No. of Installments",
  has_loans: "Has Loans",
  loans_history: "Loans History",
  basic_salary: "Basic Salary",
  deduction: "Deduction",
  note: "Note",
  personal_email: "Personal Email",
  company_email: "Company Email",
  allowances_setting: "Allowances Setting",
  company: "Company",
  unpaid_vacation: "Unpaid Vacation",
  loan_installment: "Loan Installment",
  after: "after",
  payroll_salary: "Payroll Salary",
  salary_slip: "Salary Slip",
  project: "Project",
  project_name: "Project Name",
  client_name: "Client Name",
  employee_name: "Employee Name",
  client_phone: "Client Phone",
  emergency_leave: "Emergency Leave",
  annual_leave: "Annual Leave",
  reason: "Reason",
  department: "Department",
  departments: "Departments",
  maintaince: "Maintaince",
  delete_date: "Delete Date",
  maintaince_start_date: "Maintaince Start Date",
  maintaince_end_date: "Maintaince End Date",
  assets: "Assets",
  salary: "Salary",
  salaries: "Salaries",
  total_salaries: "Total Salaries",
  employee: "Employee",
  employees: "Employees",
  request_sick_leave : "has made requests for sick leave with",
  request_salary_certificate : "has made request to issue salary certificate",
  is_requesting: "is requesting",
  for: 'for',
  days: 'days',
  choiceـshowroom: "Choice Showroom",
  freeze: "Freeze",
  dashboard: "Dashboard",
  admins: "Admins",
  admin: "Admin",
  rules: "Rules",
  users: "Users",
  categories: "Categories",
  category: "Category",
  vacation: "Vacation",
  vacations: "Vacations",
  accrued_leave: "Accrued Leave",
  sick: "Sick",
  sick_leave: "Sick Leave",
  is_sick: "Is Sick",
  sick_history: "Sick History",
  sub_categories: "SubCategories",
  sub_category: "SubCategory",
  brands: "Brands",
  variant: "Variant",
  dynamic_variant: "Dynamic Variant",
  products: "Products",
  departure_day: "Departure Day",
  return_day: "Return Day",
  print: "Print",
  product: "Product",
  digital_products: "Digital Products",
  item_no: "Item No",
  item: "Item",
  items: "Items",
  barcode: "Barcode",
  product_name: "Product Name",
  reports: "Reports",
  notification: "Notification",
  time_slot: "Time Slot",
  banners: "Banners",
  news: "News",
  new: "New",
  website_banners: "Website Banners",
  members: "Members",
  ad_screen: "Ad Screen",
  job: "Job",
  job_title: "Job Title",
  works_hours: "Works Hours",
  works_day: "Works Day",
  control: "Control",
  contact_us: "Contact Us",
  setting: "Setting",
  delivery: "Delivery",
  social_media: "Social Media",
  social: "Social Media",
  website: "Website",
  currency: "Currency",
  payment: "Payment",
  pages: "Pages",
  total_users: "Total Users",
  total_categories: "Total Categories",
  total_sub_categories: "Total Sub Categories",
  total_brands: "Total Brands",
  total_admins: "Total Admins",
  gmail: "Gmail",
  total_activities_and_events: "Total Activities and Events",
  total_board_of_directors: "Total Board Of Directors",
  total_branches_and_markets: "Total Branches and Markets",
  total_news: "Total News",
  total_offers: "Total Offers",
  total_users: "Total Users",
  on_the_way: "On the Way",
  canceled: "Canceled",
  delivered: "Delivered",
  shipped: "Shipped",
  process: "Process",
  ordered: "Ordered",
  total_sales: "Total Sales",
  total_salary: "Total Salary",
  total_salaries: "Total Salaries",
  sales: "Sales",
  daily: "Daily",
  add_admin: "Add Admin",
  add_admins: "Add Admins",
  name: "Name",
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  control: "Control",
  diwans: "Diwans",
  shareholders: "Shareholders",
  import: "Import",
  shareholders_requests: "Shareholders Requests",
  requests: "Requests",
  attachments: "Attachments",
  number_of_people: "Number of People",
  people: "People",
  number_of_days: "Number of Days",
  membership_number: "Membership Number",
  address: "Address",
  address_link: "Address Link",
  user_name: "User Name",
  password: "Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  change_password: "Change Password",
  phone: "Phone",
  country_code: "Country Code",
  permissions: "Permissions",
  status: "STATUS",
  rule: "Rule",
  full_permissions: "Full Permissions",
  full_time: "Full Time",
  part_time: "Part Time",
  some_permissions: "Some Permissions",
  civil_id: "Civil ID",
  box_number: "Box Number",
  family_card: "Family Card",
  no_permissions: "No Permissions",
  shareholder_code_number: "Shareholder Code Number",
  read_only: "Read Only",
  edit: "Edit",
  delete: "Delete",
  search_by: "Search by",
  profile: "Profile",
  logout: "LogOut",
  cancel: "Cancel",
  submit: "Submit",
  add: "Add",
  view: 'View',
  select: "Select",
  deleted: "Deleted",
  next: "Next",
  previous: "Previous",
  go_to_page: "Go to Page",
  page: "Page",
  of: "of",
  add_categories: "Add Categories",
  active: "Active",
  not_active: "Not Active",
  image: "Image",
  month: "Month",
  remove_image: "Remove Image",
  deactive: "Deactive",
  english: "English",
  arabic: "Arabic",
  category_image: "Category Image",
  close: " Close",
  price: "Price",
  in_stock: "In Stock",
  english_title: "English Title",
  english_name: "English Name",
  arabic_title: "Arabic Title",
  arabic_name: "Arabic Name",
  english_description: "English Description",
  arabic_description: "Arabic Description",
  description: "Description",
  activities: "Activities",
  activity: "Activity",
  branches_and_markets: "Branches and Markets",
  code: "Code",
  cost: "Cost",
  quantity: "Quantity",
  weight: "Weight",
  offer_price: "Offer Price",
  best_seller: "Best Seller",
  new_in: "New In",
  offer: "Offer",
  offers: "Offers",
  images: "Images",
  create: "Create",
  types_by_english: "Types by English",
  types_by_arabic: "Types by Arabic",
  add_new_value: "Add New Value",
  chosse: "Chosse",
  employee_credentials: "Employee Credentials",
  has_amount: "Has Amount",
  amount: "Amount",
  available_amount: "Available Amount",
  kwd: "KWD",
  payment_method: "Payment Method",
  total_price: "Total Price",
  sub_price: "Sub Price",
  total: "Total",
  customer_name: "Customer Name",
  exist_user: "Exist User",
  new_user: "New User",
  search: "Search",
  order_day: "Order Day",
  from: "From",
  to: "To",
  coupon: "Coupon",
  verified: "Verified",
  updated_successfully: "Updated Successfully",
  updated_status_successfully: "Updated Status Successfully",
  profile_updated_successfully: "Profile Updated Successfully",
  banners_updated_successfully: "Banners Updated Successfully",
  banners_added_successfully: "Banners Added Successfully",
  apply: "Apply",
  enter: "Enter",
  add_new_product: "Add New Product",
  date_range: "Date Range",
  export: "Export",
  visa: "Visa",
  cash: "Cash",
  approved: "Approved",
  rejected: "Rejected",
  approve: "Approve",
  reject: "Reject",
  salary_certificate: "Salary Certificate",
  end_date: "End Date",
  start_date: "Start Date",
  expiry_date: "Expiry Date",
  nationality: "Nationality",
  max_usage: "Max Usage",
  count_usage: "Count Usage",
  percentage: "Percentage",
  fixed: "Fixed",
  net_profit: "Net Profit",
  or: "Or",
  serial_number: "Serial Number",
  serial_image: "Serial Image",
  title: "Title",
  message: "Message",
  send: "Send",
  delete_message:
    "Kindly note that this action can`t be undone and the details will get deleted.",
  details: 'Details',
  welcome_back: "Welcome Back",
  sign_text: "Sign in by entering information below",
  text: "Very Good Works are Waiting for you",
  sign_in: "Sign In",
  login: "Login",
  specific_block: "Specific Block",
  capacity: "Capacity",
  interval: "Interval",
  open: "Open",
  rate: "Rate",
  country_name: "Country Name",
  country: "Country",
  banner: "Banner",
  link: "Link",
  ad: "Ad",
  add_new_screen: "Add New Screen",
  website_title: "Website Title",
  website_logo: "Website Logo",
  mobile_logo: "Mobile Logo",
  dashboard_logo: "Dashboard Logo",
  color: "Color",
  label_color: "Label Color",
  possible_delivery: "Possible Delivery",
  cash_in_delivery_fees: "Cash in Delivery Fees",
  shipping_fee: "Shipping Fee",
  price_per_kg: "Price per Kg",
  type_of_area: "Type of Area",
  all_area: "All Area",
  per_area: "Per Area",
  delivery_fees: "Delivery Fees",
  facebook: "Facebook",
  snapchat: "Snapchat",
  instagram: "Instagram",
  whatsapp: "Whatsapp",
  twitter: "Twitter",
  with: 'with',
  and: 'and',
  linked_in: "Linked In",
  telegram: "Telegram",
  tiktok: "Tiktok",
  app_store: "App Store",
  play_store: "Play Store",
  call_us: "Call Us",
  iban: "Iban",
  about: "about",
  faqs: "FAQs",
  privacy: "Privacy",
  talabat: "Talabat",
  address_name: "Address Name",
  block: "Block",
  street: "Street",
  area: "Area",
  first: "First",
  avenue: "Avenue",
  building_number: "Building Number",
  floor_number: "Floor Number",
  office_number: "Office Number",
  house_number: "House Number",
  apt_number: "Apt Number",
  other_instructions: "Other Instructions",
  delivery_day: "Delivery Day",
  time: "Time",
  confirm_added: "Confirm Added",
  added: "Added",
  confirm_des: "You can add another product by change some information",
  add_more_products: "Add More Same Product",
  reference_id: "Reference Id",
  invoice_id: "Invoice ID",
  increase: "Increase",
  holiday: "Holiday",
  login_details: "Login Details",
  human_resources: "Human Resources",
  post_date: "Post Date",
  createdAt: "CreatedAt",
  order_id: "Order Id",
  static_pages: "Static Pages",
  field_required: "This Field is required",
  ordered_successfully: "Ordered Successfully",
  you_tube: "Youtube",
  successfully: "Successfully",
  ordered_failed: "Ordered Failed",
  failed_msg: "The payment process is not complete",
  back_home: "Back to Home",
  knet: "Knet",
  bidding_management: "Bidding Management",
  app_commission_management: "App Commission Management",
  wallet_management: "Wallet Management",
  brand_management: "Brand Management",
  time_management: "Time Management",
  location: "Location",
  january:'January', 
  february: 'February', 
  march: 'March', 
  april: 'April', 
  may: 'May', 
  june: 'June', 
  july: 'July', 
  august: 'August', 
  september: 'September', 
  october: 'October', 
  november: 'November', 
  december: 'December',
  not_started: "Not Started",
  daily_indemnity: "Daily Indemnity",
  exceeding_years_indemnity: "Exceeding Years Indemnity",
  first5YearsIndemnity: "First 5 Years Indemnity",
  monthly_indemnity: "Monthly Indemnity",
  paid_leave_balance_amount: "Paid Leave Balance Amount",
  leave: "Leave",
  total_indemnity: "Total Indemnity",
  civil_id_status: "Civil Id Status",
  passport_status: "Passport Status",
  residency_status: "Residency Status",
  valid: "Valid",
  expired: "Expired",
};
