import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Translate } from "../../../Enums/Tranlate";
import EmployeesService from "../../../../services/EmployeesService";

const CertificateModal = ({modal, setModal, setFormData,setText, setShow, formData})=>{
    const [employeesOptions, setEmployeesOptions] = useState([])
    const employeesService = new EmployeesService()
    const lang = useSelector(state=> state.auth.lang)

    useEffect(()=>{
        employeesService?.getList().then(res=>{
            if(res?.status === 200){
                let data = res?.data?.data?.data?.map(emp=>{
                    return {
                        ...emp,
                        label: emp?.name,
                        value: emp.id
                    }
                })
                setEmployeesOptions(data)
            }
        })
    },[])

    const submit = (e) => {
        e.preventDefault();
        if(!formData?.employee){
          return
        }
        let info = '';
        if(formData?.type?.value === "Joining Letter"){
          setShow(true)
          info = `
            <p style="margin-bottom: 1.5rem">We are incredibly excited to have you join us as ${formData?.employee?.job_title}. I am writing this letter to confirm that your joining date would be ${formData?.date}.</p>
            <div style="display: flex; justify-content: space-between; margin-bottom: 4rem">
              <div>
                <p style="margin-bottom: 1rem">Employer Name: ${formData?.employer_name}</p>
                <p>Employer Signature: </p>
              </div>
              <div>
                <p style="margin-bottom: 1rem">Employee Name: ${formData?.employee?.name}</p>
                <p>Employee Signature: </p>
              </div>
            </div>
    
            <p style="margin-bottom: .2rem; margin-top: 0">Hr Name: ${formData?.hr_name}</p>
            <p>Hr Signature: </p>
          `
        }
        if(formData?.type?.value === "Salary Certification"){
          setShow(true)
          info = `
          <div style="font-size: 22px; margin-top: 3rem">
            <p style="margin-bottom: 1rem">Date: ${new Date().getDate()} / ${new Date().getMonth()+1} / ${new Date().getFullYear()}</p>
            <p style="margin-bottom: 1rem">TO WHOM IT MAY CONCERN</p>
            <p style="margin-bottom: 1rem">Cloud Lift Solutions Company certifies that Mr. / Miss ${formData?.employee?.name}, who holds ${formData?.employee?.nationality} nationality and has a civil ID ${formData?.employee?.civil_id}, works for us in the position of ${formData?.employee?.job_title} in the ${formData?.employee?.department?.name} department and is registered with it as of the date of ${new Date().getDate()} / ${new Date().getMonth()+1} / ${new Date().getFullYear()}. He/ She receives a monthly salary of ${formData?.employee?.salary} only.</p>
            <p style="margin-bottom: 2rem">This certificate was given to him upon his/her request to present it to whomever it may concern, without accepting any liability on behalf of this letter on our company.</p>
            <p>CEO Signature: </p>
            </div>
          `
        }
        if(formData?.type?.value === "Employee Custody Form"){
          setShow(true)
          info = `
            <p style="margin-bottom: 1rem; margin-top: 0">Employee Information:</p>
            <p style="margin-bottom: .5rem; margin-top: 0">- Name: ${formData?.employee?.name}</p>
            <p style="margin-bottom: .5rem; margin-top: 0">- Employee ID: ${formData?.employee?.civil_id}</p>
            <p style="margin-bottom: .5rem; margin-top: 0">- Department: ${formData?.employee?.department?.name}</p>
            <p style="margin-bottom: .75rem; margin-top: 0">- Position: ${formData?.employee?.job_title}</p>
            
            <p style="margin-bottom: .5rem; margin-top: 0; font-size:28px">Items Issued:</p>
            <p style="margin-bottom: .5rem; margin-top: 0 font-size:26px">1. Mobile Phone</p>
            <p style="margin-bottom: .5rem; margin-top: 0">- Brand/Model: ${formData?.employee?.employee_assets?.find(res=> res?.asset?.name?.toLocaleLowerCase()?.includes('mobile'))?.asset?.name || ''}</p>
            <p style="margin-bottom: 1.5rem; margin-top: 0">- Serial Number: ${formData?.employee?.employee_assets?.find(res=> res?.asset?.name?.toLocaleLowerCase()?.includes('mobile'))?.asset?.serial_number || ''}</p>
    
            <p style="margin-bottom: .5rem; margin-top: 0 font-size:26px">2. SIM Card</p>
            <p style="margin-bottom: .5rem; margin-top: 0">- Phone Number: ${formData?.employee?.employee_assets?.find(res=> res?.asset?.name?.toLocaleLowerCase()?.includes('sim'))?.asset?.serial_number || ''}</p>
    
            <p style="margin-bottom: .5rem; margin-top: 0 font-size:26px">3. Laptop</p>
            <p style="margin-bottom: .5rem; margin-top: 0">- Brand/Model: ${formData?.employee?.employee_assets?.find(res=> res?.asset?.name?.toLocaleLowerCase()?.includes('laptop'))?.asset?.name || ''}</p>
            <p style="margin-bottom: 1.5rem; margin-top: 0">- Serial Number: ${formData?.employee?.employee_assets?.find(res=> res?.asset?.name?.toLocaleLowerCase()?.includes('laptop'))?.asset?.serial_number || ''}</p>
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 4rem; margin-top: 2rem">
              <div>
                <p style="margin-bottom: 1rem">Employer Name: ${formData?.employer_name}</p>
                <p>Employer Signature: </p>
              </div>
              <div>
                <p style="margin-bottom: 1rem">Employee Name: ${formData?.employee?.name}</p>
                <p>Employee Signature: </p>
              </div>
            </div>
    
            <p style="margin-bottom: .2rem; margin-top: 0">Hr Name: ${formData?.hr_name}</p>
            <p>Hr Signature: </p>
          `
        }
        if(formData?.type?.value === "Company Work Rules"){
          setShow(true)
          info = `
            <p style="margin-bottom: .75rem; margin-top: 0">1. Professional Conduct: Maintain professionalism, integrity, and respect towards colleagues, clients, and partners at all times.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">2. Attendance and Punctuality: Adhere to work schedules, notify supervisors of absences promptly, and avoid repeated tardiness.</p>
            <p style="margin-bottom: .25rem; margin-top: 0">Employees shall be at the work place, ready to work, at the regular starting time. Working hours for all employees are Sunday to Thursday, 9:00 am, - 4:30 pm.</p>
            <p style="margin-bottom: .5rem; margin-top: 0">All employees are expected to be punctual and reach office on time. After 9:15 am it would be marked as late.</p>
            
            <p style="margin-bottom: .75rem; margin-top: 0">3. Dress Code: Follow the company dress code policy, wearing formal dress.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">4. Work Environment: Keep workspaces clean, follow safety procedures.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">5. Use of Company Property: Use company equipment responsibly for work purposes only and maintain confidentiality of sensitive information.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">6. Technology Use: Utilize company email and internet for business purposes, and only install authorized software on company devices and Phone number for the company.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">7. Performance Reviews: Participate in regular performance evaluations to discuss achievements and areas for improvement.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">8. Conflict Resolution: Report disputes to a supervisor or HR promptly and use mediation services if needed.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">9. Training and Development: Engage in training and professional development opportunities provided by the company.</p>
            <p style="margin-bottom: .75rem; margin-top: 0">10. Disciplinary Actions: Understand that violations of these rules may result in disciplinary actions, including warnings, suspension, or termination.</p>
    
            <div style="display: flex; justify-content: space-between; margin-bottom: 4rem; margin-top: 2rem">
              <div>
                <p style="margin-bottom: 1rem">Employer Name: ${formData?.employer_name}</p>
                <p>Employer Signature: </p>
              </div>
              <div>
                <p style="margin-bottom: 1rem">Employee Name: ${formData?.employee?.name}</p>
                <p>Employee Signature: </p>
              </div>
            </div> 
    
            <p style="margin-bottom: .1rem; margin-top: 0">Hr Name: ${formData?.hr_name}</p>
            <p>Hr Signature: </p>
          `
        }
        if(formData?.type?.value === "Job Offer Letter"){
          setShow(true)
          info = `
            <p style="margin-bottom: 0.5rem; margin-top: 0">Dear ${formData?.employee?.name}</p>
            <p style="margin-bottom: 1rem; margin-top: 0">On behalf of Cloud Lift Solutions, we are pleased to offer you the position as ${formData?.employee?.job_title} & trusting that your knowledge, skills and experience will be among our most valuable assets.</p>
            <p style="margin-bottom: 0.1rem; margin-top: 0">Job Title: ${formData?.employee?.job_title}</p>
            <p style="margin-bottom: 2rem; margin-top: 0">Monthly Salary: ${formData?.employee?.salary}</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Working hours: 7:30 hours (9 AM – 4:30 PM)</p>
            <p style="margin-bottom: 1rem; margin-top: 0">Additional working hours maybe necessary based on performance Official Joining date: ${formData?.date}</p>
            <p style="margin-bottom: 1rem; margin-top: 0">Reports To:</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Offer Letter Validity: This offer is valid for 3 days from its date</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">All Information stated in the employment of contract and Kuwait labor law is applied automatically for other terms and condition not stipulated here in</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Please sign the enclosed copy of this letter as indication of your acceptance of this offer</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">We are confident you will be able to make a significant contribution on the success of Cloudlift Solutions.</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Sincerely</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Chief Executive officer</p>
            <p style="margin-bottom: 3rem; margin-top: 0">I have read and fully understood the content of this job offer letter and I hereby accept this offer lawfully</p>
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 4rem; margin-top: 2rem">
              <div>
                <p style="margin-bottom: 1rem">Employer Name: ${formData?.employer_name}</p>
                <p>Employer Signature: </p>
              </div>
              <div>
                <p style="margin-bottom: 1rem">Employee Name: ${formData?.employee?.name}</p>
                <p>Employee Signature: </p>
              </div>
            </div>
    
            <p style="margin-bottom: 0.1rem; margin-top: 0">Hr Name: ${formData?.hr_name}</p>
            <p>Hr Signature: </p>
          `
        }
        if(formData?.type?.value === "Salary Form"){
          setShow(true)
          info = `
            <p style="margin-bottom: 0.5rem; margin-top: 0">Date : ${formData?.date} </p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Employee information :</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">- Name: ${formData?.employee?.job_title}</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">- Civil id: ${formData?.employee?.civil_id}</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">- Department: ${formData?.employee?.department?.name}</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">- Position: ${formData?.employee?.job_title}</p>
            <p style="margin-bottom: 2.5rem; margin-top: 0">- Actual Salary: ${formData?.employee?.salary}</p>

            <div style="display: flex; justify-content: space-between; margin-bottom: 4rem; margin-top: 2rem">
              <div>
                <p style="margin-bottom: 1rem">Employer Name: ${formData?.employer_name}</p>
                <p>Employer Signature: </p>
              </div>
              <div>
                <p style="margin-bottom: 1rem">Employee Name: ${formData?.employee?.name}</p>
                <p>Employee Signature: </p>
              </div>
            </div> 
    
            <p style="margin-bottom: 0.1rem; margin-top: 0">Hr Name: ${formData?.hr_name}</p>
            <p>Hr Signature: </p>
          `
        }
        if(formData?.type?.value === "Vacation Form"){
          setShow(true)
          info = `
            <p style="margin-bottom: 0.5rem; margin-top: 0">Employee information</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Name: ${formData?.employee?.name}</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Department: ${formData?.employee?.department?.name}</p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">
              Leave request: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <input type="checkbox" name="days" value="days"> &nbsp;&nbsp; Days  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <input type="checkbox" /> &nbsp;&nbsp; Hours
            </p>
            <p style="margin-bottom: 0.5rem; margin-top: 0">Dates of absence. From ${formData?.absence_from} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To: ${formData?.absence_to}</p>
            <p style="margin: 2rem 0">Type of leave: Vacation</p>
            <p style="margin: 2rem 0 3rem">Reason for the Leave request</p>
            <p  style="margin-bottom: 1rem; margin-top: 0">I understand that this request is subject to approval by my employer.</p>
           
            <div style="display: flex; justify-content: space-between; margin-bottom: 4rem">
              <p>Employee Signature: </p>
              <p>Date: __________________________ </p>
            </div>
    
            <div style="border-top: 3px dotted; padding-top: 8px; margin-top: 2rem; margin-bottom: 1rem;">
              <p style="margin-top: 0; margin-bottom: 0.5rem;">Manager approval</p>
              <input type="checkbox" name="Approved" value="Approved"> &nbsp;&nbsp; Approved  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <input type="checkbox" /> &nbsp;&nbsp; Rejected
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 4rem">
              <p>Manager Signature: </p>
              <p>Date: __________________________ </p>
            </div>  
          `
        }
        if(formData?.type?.value === "Internship Certification"){
          setShow(true)
          info = `
            <p style="margin-bottom: 2rem; margin-top: 4rem">This is to certify that ${formData?.employee?.name} has successfully completed ${formData?.month} month internship in the ${formData?.employee?.department?.name} Department at ${formData?.company}.</p>
            <p style="margin-bottom: 2rem; margin-top: 0">During this course he/she showed diligence, consistency, determination, active participation and innovation throughout their internship period</p>
            <p style="margin-bottom: 2.5rem; margin-top: 0">CEO SIGNATURE</p>
          `
        }
        setText(info)
        setModal()
      }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={modal} onHide={()=>{
            setModal()
            }}>
            <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{Translate[lang]?.create}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
            <Row>
              {(formData?.type?.value === "Salary Certification" || 
                formData?.type?.value === "Employee Custody Form" ||
                formData?.type?.value === "Company Work Rules" ||
                formData?.type?.value === "Salary Form" ||
                formData?.type?.value === "Vacation Form" ||
                formData?.type?.value === "Internship Certification"
                ) && <Col md={6} className="mb-3">
                  <label className="text-label">
                      {Translate[lang].employee}
                  </label>
                  <Select
                      placeholder={Translate[lang]?.select}
                      options={employeesOptions}
                      value={formData.employee}
                      onChange={(e) => setFormData({...formData, employee: e})}
                  />
              </Col>}
              {(formData?.type?.value === "Joining Letter" ||
                formData?.type?.value === "Job Offer Letter") && <>
                    <Col md={6}>
                            <AvField
                                label={Translate[lang]?.employee}
                                type='text'
                                placeholder={Translate[lang]?.employee}
                                bsSize="lg"
                                name='employee'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.employee?.name}
                                onChange={(e) => setFormData({...formData, employee: {...formData?.employee, name: e.target.value}})}
                            />
                    </Col>
                    <Col md={6}>
                            <AvField
                                label={Translate[lang]?.job_title}
                                type='text'
                                placeholder={Translate[lang]?.job_title}
                                bsSize="lg"
                                name='job_title'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.employee?.job_title}
                                onChange={(e) => setFormData({...formData, employee: {...formData?.employee, job_title: e.target.value}})}
                            />
                    </Col>
              </>}
              {(formData?.type?.value === "Job Offer Letter") && <>
                    <Col md={6}>
                            <AvField
                                label={Translate[lang]?.salary}
                                type='number'
                                placeholder={Translate[lang]?.salary}
                                bsSize="lg"
                                name='salary'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.employee?.salary}
                                onChange={(e) => setFormData({...formData, employee: {...formData?.employee, salary: e.target.value}})}
                            />
                    </Col>
              </>}
              {(formData?.type?.value === "Joining Letter" || 
                formData?.type?.value === "Employee Custody Form" ||
                formData?.type?.value === "Job Offer Letter" ||
                formData?.type?.value === "Salary Form" ||
                formData?.type?.value === "Company Work Rules" ) && <Col md={6}>
                            <AvField
                                label={Translate[lang]?.employer_name}
                                type='text'
                                placeholder={Translate[lang]?.employer_name}
                                bsSize="lg"
                                name='employer_name'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.employer_name}
                                onChange={(e) => setFormData({...formData, employer_name: e.target.value})}
                            />
  
              </Col>}
              
              {(formData?.type?.value === "Joining Letter" || 
              formData?.type?.value === "Job Offer Letter" ||
              formData?.type?.value === "Salary Form")&& <Col md={6}>
                <AvField
                    label={Translate[lang]?.date}
                    type='date'
                    placeholder={Translate[lang]?.date}
                    bsSize="lg"
                    name='date'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: Translate[lang].field_required
                        }
                    }}
                    value={formData.date}
                    onChange={(e) => setFormData({...formData, date: e.target.value})}
                />
  
              </Col>}
              {(formData?.type?.value === "Vacation Form") && <>
              <Col md={6}>
                <AvField
                    label={Translate[lang]?.from}
                    type='date'
                    placeholder={Translate[lang]?.from}
                    bsSize="lg"
                    name='absence_from'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: Translate[lang].field_required
                        }
                    }}
                    value={formData.absence_from}
                    onChange={(e) => setFormData({...formData, absence_from: e.target.value})}
                />
              </Col>
              <Col md={6}>
                <AvField
                    label={Translate[lang]?.to}
                    type='date'
                    placeholder={Translate[lang]?.to}
                    bsSize="lg"
                    name='absence_to'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: Translate[lang].field_required
                        }
                    }}
                    value={formData.absence_to}
                    onChange={(e) => setFormData({...formData, absence_to: e.target.value})}
                />
              </Col>
              </>}
              {(formData?.type?.value === "Internship Certification") && <>
              <Col md={6}>
                <AvField
                    label={Translate[lang]?.month}
                    type='text'
                    placeholder='ex: One, Two, Three'
                    bsSize="lg"
                    name='month'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: Translate[lang].field_required
                        }
                    }}
                    value={formData.month}
                    onChange={(e) => setFormData({...formData, month: e.target.value})}
                />
              </Col>
              <Col md={6}>
                <AvField
                    label={Translate[lang]?.company}
                    type='text'
                    placeholder={Translate[lang]?.company}
                    bsSize="lg"
                    name='company'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: Translate[lang].field_required
                        }
                    }}
                    value={formData.company}
                    onChange={(e) => setFormData({...formData, company: e.target.value})}
                />
              </Col>
              </>}
              {(formData?.type?.value === "Joining Letter" || 
                formData?.type?.value === "Job Offer Letter" ||
                formData?.type?.value === "Employee Custody Form" ||
                formData?.type?.value === "Salary Form" ||
                formData?.type?.value === "Company Work Rules" ) && <Col md={6}>
                <AvField
                    label={Translate[lang]?.hr_name}
                    type='text'
                    placeholder={Translate[lang]?.hr_name}
                    bsSize="lg"
                    name='hr_name'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: Translate[lang].field_required
                        }
                    }}
                    value={formData.hr_name}
                    onChange={(e) => setFormData({...formData, hr_name: e.target.value})}
                />
  
              </Col>}
            </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={!formData?.employee}
                >{Translate[lang]?.add}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default CertificateModal;